<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img class="add" src="@/assets/address.png" />
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img class="right" src="@/assets/breadr.png" />
        <router-link :to="{ name: 'SwordCulture' }">剑文化</router-link>
      </div>
      <div class="sword-wrap">
        <div class="tab-wrap">
          <div class="tab">
            <a
              v-for="item in types"
              :key="item.id + 'type'"
              :class="{ active: nowType === item.id }"
              @click="serachType(item.id)"
              href="javascript:;"
              >{{ item.name }}</a
            >
          </div>
        </div>
        <div class="content">
          <div class="top">
            <div class="banner">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="i in rightNews" :key="i.id">
                    <router-link
                      :to="{ name: 'SwordCultureDetail', params: { id: i.id } }"
                    >
                      <img :src="i.image" />
                      <div class="text">
                        <div class="top">
                          <p class="ellipsis">{{ i.title }}</p>
                          <span>{{new Date(i.createtime*1000).getFullYear()}}-{{new Date(i.createtime*1000).getMonth()+1}}-{{new Date(i.createtime*1000).getDate()}}</span>
                        </div>
                        <p class="des ellipsis">{{ i.sub_title }}</p>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="swiper-pagination"></div>

                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>

              <!-- <el-carousel trigger="click" height="568px">
                <el-carousel-item v-for="item in news" :key="item.id">
                  <img
                    style="height: 100%"
                    :src="item.image"
                    @click="jumpDetails(item.id)"
                    alt=""
                  />
                </el-carousel-item>
              </el-carousel> -->
            </div>
            <div class="text">
              <ul>
                <li v-for="i in rightNews" :key="i.id">
                  <div class="left">
                    <p>{{ new Date(i.createtime * 1000).getDate() }}</p>
                    <span
                      >{{ new Date(i.createtime * 1000).getFullYear() }}-{{
                        new Date(i.createtime * 1000).getMonth() + 1
                      }}</span
                    >
                  </div>
                  <div class="right">
                    <router-link
                      class="ellipsis"
                      :to="{ name: 'SwordCultureDetail', params: { id: i.id } }"
                      >{{ i.title }}</router-link
                    >
                    <p class="ellipsis-s">
                      {{ i.sub_title }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="bottom">
            <ul class="text-list">
              <template v-for="item,index in news">
                <li :key="item.id + 'news'" v-if="index>2">
                  <router-link
                    :to="{
                      name: 'SwordCultureDetail',
                      params: { id: item.id },
                    }"
                  >
                    <div class="left">
                      <p class="title ellipsis-s">{{ item.title }}</p>
                      <span class="time">{{
                        item.createtime | formatdate
                      }}</span>
                      <p class="des ellipsis-s">{{ item.sub_title }}</p>
                      <span class="btn">查看详情+</span>
                    </div>
                    <div class="right">
                      <img :src="item.image" />
                    </div>
                  </router-link>
                </li>
              </template>
            </ul>
            <div class="pc-page">
              <!-- <paginate
                :page-count="pagination.pageCount"
                :page-range="pagination.pageRange"
                :margin-pages="pagination.marginPages"
                :initial-page="pagination.initPage"
                :click-handler="clickCallback"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate> -->
            </div>
            <!-- <div class="m-page">
              <a href="javascript:;">加载更多</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
// import Paginate from 'vuejs-paginate'
import { getNewType, getNews } from "@/api";
import { formatDate } from "@/utils";
export default {
  name: "SwordCulture",
  filters: {
    breadTitle(route) {
      const obj = {
        qyjj: "企业简介",
        fzlc: "发展历程",
        qywh: "企业文化",
        zzry: "资质荣誉",
        dsfc: "大师风采",
        dzgy: "锻造工艺",
      };
      return obj[route];
    },
    formatdate(time) {
      return formatDate(new Date(time * 1000), "yyyy-MM-dd");
    },
  },
  components: {
    // Paginate,
  },
  data() {
    return {
      types: [],
      nowType: "",
      news: [],
      pagination: {
        pageCount: 0,
        pageRange: 3,
        marginPages: 2,
        initPage: 1,
      },
      rightNews: [],
    };
  },
  deactivated() {
    window.localStorage.setItem("active", JSON.stringify(this.nowType));
  },
  activated() {
    this.nowType = window.localStorage.getItem("active")
      ? JSON.parse(window.localStorage.getItem("active"))
      : null;
  },
  mounted() {
    this.initBanner();
    getNewType({
      type: 0,
    })
      .then((res) => {
        if (res.data.code) {
          this.types = res.data.data;
          if (this.types.length) {
            this.nowType = this.types[0].id;
            this.init();
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    initBanner() {
      // eslint-disable-next-line no-unused-vars
      const mySwiper = new Swiper(".swiper-container", {
        loop: true, // 循环模式选项
        observer: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    init() {
      getNews({ type_id: this.nowType })
        .then((res) => {
          if (res.data.code) {
            this.news = res.data.data;
            this.rightNews = [];
            for (let i = 0; i < this.news.length; i++) {
              if (i < 3) {
                this.rightNews.push(this.news[i]);
              } else {
                return;
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    clickCallback(pageNum) {
      console.log(pageNum);
    },
    serachType(id) {
      this.nowType = id;
      this.init();
    },
    jumpDetails(id) {
      this.$router.push({
        name: "SwordCultureDetail",
        params: { id: id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .wrap {
    // min-width: 1760px;
    background: #f2f2f2;
    padding: 0 20px;
    > div {
      // width: 1760px;
      margin: 0 auto;
      .sword-wrap {
        background: #fff;
        padding: 25px 0;
        > .tab-wrap {
          > .tab {
            text-align: center;
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 25px;
            margin: 0 32px;
          }
        }

        > .content {
          padding: 50px 80px;
          > .top {
            overflow: hidden;
            > .banner {
              float: left;
              width: 50%;
              height: 530px;
              > .swiper-container {
                height: 100%;
                > .swiper-wrapper {
                  > .swiper-slide {
                    > a {
                      display: block;
                      height: 100%;
                      > img {
                        width: 100%;
                        height: 100%;
                      }
                      > .text {
                        display: none;
                      }
                    }
                  }
                }
                .swiper-pagination {
                  display: none;
                }
                .swiper-button-next {
                  right: 0;
                }
                .swiper-button-prev {
                  left: 0;
                }
                .swiper-button-prev,
                .swiper-button-next {
                  width: 46px !important;
                  height: 142px !important;
                  background: rgba(77, 77, 77, 0.5);
                  transform: translateY(-50%);
                }
                .swiper-button-next:after,
                .swiper-button-prev:after {
                  color: #fff;
                  font-size: 25px;
                }
              }
            }
            > .text {
              float: right;
              width: 48%;
              > ul {
                > li {
                  overflow: hidden;
                  margin-bottom: 20px;
                  height: 176px;
                  background: #f8f8f8;
                  box-sizing: border-box;
                  padding: 24px 44px 24px 25px;
                  display: flex;
                  > .left {
                    // width: 90px;
                    text-align: center;
                    margin-top: 22px;
                    margin-right: 10px;
                    > p {
                      font-size: 40px;
                      font-weight: 500;
                      color: #666666;
                      line-height: 56px;
                      font-family: PingFangSC-Medium, PingFang SC;
                    }
                    > span {
                      font-size: 20px;
                      color: #666666;
                      line-height: 28px;
                      font-family: PingFangSC-Regular, PingFang SC;
                    }
                  }
                  > .right {
                    flex: 1;
                    > a {
                      font-size: 24px;
                      font-weight: bold;
                      color: #333333;
                      line-height: 33px;
                      display: block;
                      font-family: PingFangSC-Medium, PingFang SC;
                    }
                    > p {
                      margin-top: 15px;
                      font-size: 18px;
                      color: #666666;
                      line-height: 40px;
                      font-family: PingFangSC-Regular, PingFang SC;
                    }
                  }
                }
                > li:nth-last-child(1) {
                  margin-bottom: 0;
                }
              }
            }
          }
          > .bottom {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > div {
      .sword-wrap {
        .tab-wrap {
          padding: 14px 15px;
          background: #fff;
        }
        > .content {
          > .top {
            background: #fff;
            padding: 0 15px;
            > .banner {
              > .swiper-container {
                > .swiper-wrapper {
                  > .swiper-slide {
                    height: auto;
                    > a {
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      > img {
                        width: 100%;
                        height: 260px;
                      }
                      > .text {
                        padding: 12px 12px 30px;
                        > .top {
                          display: flex;
                          justify-content: space-between;
                          > p {
                            font-size: 15px;
                            font-weight: bold;
                            color: #333333;
                            line-height: 21px;
                            font-family: PingFangSC-Medium, PingFang SC;
                          }
                          > span {
                            font-size: 12px;
                            color: #999999;
                            line-height: 17px;
                            font-family: PingFangSC-Regular, PingFang SC;
                          }
                        }
                        > .des {
                          margin-top: 6px;
                          font-size: 13px;
                          font-weight: 400;
                          color: #666666;
                          line-height: 18px;
                        }
                      }
                    }
                  }
                }

                /deep/ .swiper-pagination-bullet {
                  background: #bb9663;
                }
                /deep/ .swiper-pagination-bullet-active {
                  background: #bb9663;
                }

                .swiper-button-prev,
                .swiper-button-next {
                  display: none;
                }
              }
            }
            > .text {
              display: none;
            }
          }
          > .bottom {
            margin-top: 10px;
            background: #fff;
            padding: 0 12px;

            .m-page {
              padding: 20px 0;
            }
          }
        }
      }
    }
  }
}
</style>